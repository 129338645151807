/* eslint-env browser */
/* eslint-disable complexity */
import React from "react";
import {useForm, useFormState} from "react-hook-form";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import DeveloperMode from "@mui/icons-material/DeveloperMode";
import PhonelinkLock from "@mui/icons-material/PhonelinkLock";
import PhonelinkRing from "@mui/icons-material/PhonelinkRing";
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import CancelButton from "../../components/primitives/CancelButton.jsx";
import DialogTitleBar from "../../components/primitives/DialogTitleBar.jsx";
import IconButtonWithTooltip from "../../components/primitives/IconButtonWithTooltip.jsx";
import DeviceActionsWarningMessages from "./DeviceActionsWarningMessages.jsx";

function DeviceOperationalStateForm({
  // Props
  devices,
  checkedRows,
  multipleDevices,
  setCheckedRows,
  setTableReload,
}) {
  const {isInAnyRole, facilityDisabled} = useJwt();

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const [actionType, setActionType] = React.useState({});
  const [eligibleDevices, setEligibleDevices] = React.useState([]);
  const [ineligibleDevices, setIneligibleDevices] = React.useState([]);
  const [devicesInActiveStudies, setDevicesInActiveStudies] = React.useState([]);
  const handleOpen = (type) => {
    const activeStudyDevices = checkedRows.filter((checkedDevice) => !checkedDevice.availableForStudy);
    setDevicesInActiveStudies(activeStudyDevices);

    const eligible = [];
    const ineligible = [];
    checkedRows.forEach((checkedDevice) => {
      let eligibleDevice;
      const belongsToTransferFacility = !!checkedDevice.facility?.iccidTransferAgreement;
      const pendingChange = checkedDevice.pendingState !== null;
      if (type === "suspend") {
        const isActive = checkedDevice.operationalState === "active";
        eligibleDevice = isActive && !pendingChange && !belongsToTransferFacility;
      } else if (type === "unsuspend") {
        const isDeactivated = checkedDevice.operationalState === "inactive";
        eligibleDevice = isDeactivated && !pendingChange && !belongsToTransferFacility;
      } else if (type === "transfer") {
        const isNotTransferred = checkedDevice.operationalState !== "transferred";
        eligibleDevice = belongsToTransferFacility && isNotTransferred && !pendingChange;
      }

      if (eligibleDevice) {
        eligible.push(checkedDevice);
      } else {
        ineligible.push(checkedDevice);
      }
    });

    setEligibleDevices(eligible);
    setIneligibleDevices(ineligible);

    let readableAction = {};
    switch (type) {
      case "suspend":
        readableAction = {
          state: "inactive",
          presentTense: "suspend",
          presentTenseUppercase: "Suspend",
          pastTense: "suspended",
          nounUppercase: "Suspension",
        };
        break;
      case "unsuspend":
        readableAction = {
          state: "active",
          presentTense: "activate",
          presentTenseUppercase: "Activate",
          pastTense: "activated",
          nounUppercase: "Activation",
        };
        break;
      case "transfer":
        readableAction = {
          state: "transferred",
          presentTense: "transfer",
          presentTenseUppercase: "Transfer",
          pastTense: "transferred",
          nounUppercase: "Transfer",
        };
        break;
      default:
        throw new Error(`Cannot confirm unsupported action type: ${actionType}`);
    }

    setActionType(readableAction);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  //---------------------------------------------------------------------------
  // Operational State Button Management
  //---------------------------------------------------------------------------
  const showChangeStatusButton = React.useCallback(
    (roles = ["tzAdmin", "facilityAdmin", "warehouse"]) => {
      return isInAnyRole(roles) && !facilityDisabled && checkedRows[0]?.iccid !== "";
    },
    [isInAnyRole, facilityDisabled, checkedRows]
  );

  const canBeTransferred = React.useCallback((aDevice) => {
    return (
      !!aDevice?.facility?.iccidTransferAgreement &&
      aDevice?.operationalState !== "transferred" &&
      aDevice?.pendingState === null
    );
  }, []);

  const includesTransferredFacilityDevices = React.useCallback(() => {
    return checkedRows.find((checkedDevice) => {
      return canBeTransferred(checkedDevice);
    });
  }, [checkedRows, canBeTransferred]);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control} = useForm();
  const {isDirty} = useFormState({control});
  const onSubmit = async () => {
    // Disable the submit button and display the spinner
    setLoading(true);

    let successes = [];
    let failures = [];
    if (multipleDevices) {
      const devicesAndStates = eligibleDevices.map(({tzSerial, facilityId}) => {
        // Need to treat transferring like assigning to a transferred facility
        if (actionType.state === "transferred") {
          return {tzSerial, properties: {operationalState: actionType.state, facilityId}};
        }
        return {tzSerial, properties: {operationalState: actionType.state}};
      });

      let response;
      try {
        response = await axios({
          method: "patch",
          url: "/devices",
          data: devicesAndStates,
        });
      } catch {
        /* do nothing */
      }

      const patchResponse = response.data;

      successes = patchResponse.success;
      failures = patchResponse.failure.map((failure) => {
        return {
          ...failure,

          // If there is not a failure.tzSerial, there was an error finding the device by ID
          // Get the serial from the list of devices that was submitted instead of from the response
          ...(!failure.tzSerial && {
            tzSerial: devices.find((deviceEntry) => deviceEntry.id === failure.id).tzSerial,
          }),
        };
      });
    } else {
      try {
        if (actionType.state === "transferred") {
          await axios({
            method: "patch",
            url: `/devices/${checkedRows[0]?.id}`,
            data: {facilityId: checkedRows[0]?.facilityId, operationalState: actionType.state},
          });
        } else {
          await axios({
            method: "patch",
            url: `/devices/${checkedRows[0]?.id}`,
            data: {operationalState: actionType.state},
          });
        }
        successes.push(checkedRows[0]);
      } catch (err) {
        failures.push(checkedRows[0]);
      }
    }

    const confirmationTitle = `Device ${actionType.nounUppercase} Results`;

    const confirmationMessage = (
      <Grid container spacing={2}>
        <Divider pb={3} />
        {failures.length > 0 && (
          <Grid item xs={12}>
            <Alert
              message="Failures were present: Devices either didn't exist or were already going to another state"
              level="error"
            />
          </Grid>
        )}
        {successes.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography>
                <b>
                  Successfully {actionType.pastTense}: ({successes.length})
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {successes.map((item) => (
                <li key={item.tzSerial}>{item.tzSerial}</li>
              ))}
            </Grid>
          </>
        )}
        {failures.length > 0 && (
          <>
            <Divider />
            <Grid item xs={12}>
              <Typography>
                <b>
                  Failed to {actionType.presentTense}: ({failures.length})
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {failures.map((item) => (
                <li key={item.tzSerial}>{item.tzSerial}</li>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    );

    try {
      await confirm({
        title: confirmationTitle,
        content: confirmationMessage,
        confirmationText: "Ok",
        dialogProps: {maxWidth: "xs"},
      });
    } catch {
      /* do nothing */
    }
    setLoading(false);
    setCheckedRows([]);
    handleClose();

    // Update the reload state to trigger a data re-fetch
    setTableReload(true);
  };

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {!multipleDevices && (
        <>
          {showChangeStatusButton() &&
            checkedRows[0]?.status === "Suspended" &&
            checkedRows[0]?.facilityId &&
            !canBeTransferred(checkedRows[0]) && (
              <IconButtonWithTooltip
                title="Activate Device"
                color="secondary"
                data-cy={`${checkedRows[0]?.tzSerial}-activate-button`}
                onClick={() => handleOpen("unsuspend")}
                otherProps={{disabled: loading || checkedRows[0]?.pendingState !== null}}
              >
                <PhonelinkRing />
              </IconButtonWithTooltip>
            )}
          {showChangeStatusButton() &&
            ["No SIM Card", "Active - On Patient", "Active - Idle", "Active - Offline"].includes(
              checkedRows[0]?.status
            ) &&
            checkedRows[0]?.facilityId &&
            !canBeTransferred(checkedRows[0]) && (
              <IconButtonWithTooltip
                title="Suspend Device"
                color="secondary"
                data-cy={`${checkedRows[0]?.tzSerial}-suspend-button`}
                onClick={() => handleOpen("suspend")}
                otherProps={{disabled: loading || checkedRows[0]?.pendingState !== null}}
              >
                <PhonelinkLock />
              </IconButtonWithTooltip>
            )}
          {showChangeStatusButton(["tzAdmin", "warehouse"]) &&
            checkedRows[0]?.operationalState !== "transferred" &&
            checkedRows[0]?.facilityId &&
            canBeTransferred(checkedRows[0]) && (
              <IconButtonWithTooltip
                title="Transfer Device"
                color="secondary"
                data-cy={`${checkedRows[0]?.tzSerial}-transfer-button`}
                onClick={() => handleOpen("transfer")}
                otherProps={{disabled: loading || checkedRows[0]?.pendingState !== null}}
              >
                <DeveloperMode />
              </IconButtonWithTooltip>
            )}
        </>
      )}
      {multipleDevices && !facilityDisabled && (
        <>
          <IconButtonWithTooltip
            title="Activate Devices"
            data-cy="devices-activate-button"
            otherProps={{sx: {color: "white"}}}
            onClick={() => handleOpen("unsuspend")}
          >
            <PhonelinkRing />
          </IconButtonWithTooltip>
          <IconButtonWithTooltip
            title="Suspend Devices"
            data-cy="devices-suspend-button"
            otherProps={{sx: {color: "white"}}}
            onClick={() => handleOpen("suspend")}
          >
            <PhonelinkLock />
          </IconButtonWithTooltip>
          {isInAnyRole(["tzAdmin", "warehouse"]) && includesTransferredFacilityDevices() && (
            <IconButtonWithTooltip
              title="Transfer Devices"
              data-cy="devices-transfer-button"
              otherProps={{sx: {color: "white"}}}
              onClick={() => handleOpen("transfer")}
            >
              <DeveloperMode />
            </IconButtonWithTooltip>
          )}
        </>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth data-cy="insurance-type-form">
        <Alert message={error} setMessage={setError} level="error" />
        <DialogTitleBar
          title={`${actionType.presentTenseUppercase} the following ${multipleDevices ? "devices" : "device"}?`}
          icon={<Smartphone color="secondary" />}
        />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            {multipleDevices && (
              <>
                <Grid container>
                  <>
                    <Grid item xs={12}>
                      <Typography data-cy="total-selected-devices">
                        <b>Total selected devices: {checkedRows.length}</b>
                      </Typography>
                    </Grid>
                    {ineligibleDevices.length !== 0 && (
                      <Grid item xs={12}>
                        {actionType.presentTense !== "transfer" && (
                          <Typography component="div">
                            Total ineligible devices:{" "}
                            <Box sx={{display: "inline", color: "red"}} data-cy="total-ineligible-devices">
                              {ineligibleDevices.length}
                            </Box>
                          </Typography>
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography data-cy="total-eligible-devices">
                        <b>
                          Total to {actionType.presentTense}: {eligibleDevices.length}
                        </b>
                      </Typography>
                    </Grid>
                  </>
                </Grid>
                <Divider sx={{my: 2}} />
              </>
            )}
            <Grid container spacing={2}>
              {ineligibleDevices.length !== 0 && (
                <Grid item xs={12}>
                  <Alert
                    message={
                      <>
                        {actionType.presentTense !== "transfer" && (
                          <Typography variant="body2">
                            {`The following devices cannot be ${actionType.pastTense} because `}
                            {isInAnyRole(["tzAdmin", "warehouse"]) && (
                              <span>
                                they are assigned to a facility that has an ICCID transfer agreement,{" "}
                              </span>
                            )}
                            {`they are already ${actionType.pastTense}, or they are transitioning to a new state.`}
                          </Typography>
                        )}
                        {actionType.presentTense === "transfer" && (
                          <Typography variant="body2">
                            {`The following devices cannot be ${actionType.pastTense} they do not belong to an ICCID transfer facility, or they are transitioning to a new state.`}
                          </Typography>
                        )}
                        <br />
                        {ineligibleDevices.map((ineligibleDevice) => (
                          <li key={ineligibleDevice.tzSerial}>{ineligibleDevice.tzSerial}</li>
                        ))}
                      </>
                    }
                    level="error"
                  />
                </Grid>
              )}
              {!multipleDevices && (
                <>
                  <Grid item xs={12}>
                    <Typography data-cy="selected-device-serial">
                      <b>Selected device: {checkedRows[0]?.tzSerial}</b>
                    </Typography>
                  </Grid>
                  {ineligibleDevices.length !== 0 && (
                    <Grid item xs={12}>
                      <Alert
                        message={
                          <>
                            {actionType.presentTense !== "transfer" && (
                              <Typography variant="body2">
                                {`This device cannot be ${actionType.pastTense} because `}
                                {isInAnyRole(["tzAdmin", "warehouse"]) && (
                                  <span>
                                    it is assigned to a facility that has an ICCID transfer agreement,{" "}
                                  </span>
                                )}
                                {`it is already ${actionType.pastTense}, or it is transitioning to a new state.`}
                              </Typography>
                            )}
                            {actionType.presentTense === "transfer" && (
                              <Typography variant="body2">
                                {`This devices cannot be ${actionType.pastTense} because it does not belong to an ICCID transfer facility, or it is transitioning to a new state.`}
                              </Typography>
                            )}
                          </>
                        }
                        level="error"
                      />
                    </Grid>
                  )}
                </>
              )}
              <Box sx={{ml: 2}}>
                <DeviceActionsWarningMessages
                  actionType={actionType.presentTense}
                  devicesInActiveStudy={devicesInActiveStudies}
                  multipleDevices={multipleDevices}
                />
                <Box sx={{mt: 2}}>
                  <Alert
                    message="Devices are not exempt from billing unless they have been suspended for a full month."
                    level="info"
                  />
                </Box>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
                Cancel
              </CancelButton>
            </Box>
            <Box m={2}>
              <LoadingButton
                data-cy="submit-button"
                disabled={loading || eligibleDevices.length === 0}
                variant="contained"
                color="secondary"
                loading={loading}
                type="submit"
              >
                {`${actionType.presentTense} ${multipleDevices ? `(${eligibleDevices.length})` : ""}`}
              </LoadingButton>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

DeviceOperationalStateForm.propTypes = {
  devices: PropTypes.array.isRequired,
  checkedRows: PropTypes.array.isRequired,
  multipleDevices: PropTypes.bool.isRequired,
  setCheckedRows: PropTypes.func.isRequired,
  setTableReload: PropTypes.func.isRequired,
};

export default DeviceOperationalStateForm;
