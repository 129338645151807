/* eslint-env browser */
/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "../../components/primitives/Alert.jsx";

function DeviceActionsWarningMessages({
  // Props
  actionType,
  devicesInActiveStudy,
  multipleDevices,
}) {
  //----------------------------------------------------------------------------
  // Reusable component for warning messages for devices in active studies
  //----------------------------------------------------------------------------
  const warningMessage = React.useCallback(
    (text) => {
      return (
        <>
          {text}
          {multipleDevices && (
            <>
              <br />
              <br />
              Devices in active studies:
              <br />
              {devicesInActiveStudy.map((deviceInStudy) => (
                <li key={deviceInStudy.tzSerial}>{deviceInStudy.tzSerial}</li>
              ))}
            </>
          )}
        </>
      );
    },
    [devicesInActiveStudy, multipleDevices]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {actionType === "updateFirmware" && devicesInActiveStudy.length > 0 && (
        <Box mt={2} data-cy="update-firmware-warning">
          <Alert
            message={warningMessage(
              "Update firmware actions will not be downloaded until devices have completed active studies."
            )}
            level="warning"
          />
        </Box>
      )}
      {actionType === "updateForwarding" && devicesInActiveStudy.length > 0 && (
        <Box mt={2} data-cy="update-forwarding-warning">
          <Alert
            message={warningMessage(
              "Devices in active studies will not update their forwarding until their studies are complete."
            )}
            level="warning"
          />
        </Box>
      )}
      {actionType.startsWith("reassign") && devicesInActiveStudy.length > 0 && (
        <Box mt={2} data-cy="reassign-warning">
          <Alert
            message={warningMessage(
              "Devices in active studies will not be reassigned until their studies are complete."
            )}
            level="warning"
          />
        </Box>
      )}
      {actionType === "forceCheckIn" && (
        <Box mt={2}>
          <Alert
            message="Warning: Remotely checking in the device will delete all patient data from the device."
            level="warning"
          />
          <Typography variant="body2" mt={3}>
            Please enter the device serial number below to check in the device.
          </Typography>
        </Box>
      )}
      {actionType === "suspend" && devicesInActiveStudy.length > 0 && (
        <Box mt={2} data-cy="suspend-device-warning">
          <Alert
            message={warningMessage(
              "Devices in active studies will not be suspended until their studies are complete."
            )}
            level="warning"
          />
        </Box>
      )}
    </>
  );
}

DeviceActionsWarningMessages.propTypes = {
  actionType: PropTypes.string,
  devicesInActiveStudy: PropTypes.array,
  multipleDevices: PropTypes.bool,
};

export default DeviceActionsWarningMessages;
